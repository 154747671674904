import "./article.css";
import "./Lightbox.css";
import {Lightbox} from "./Lightbox";
import {Link} from "react-router-dom";


function ExtLink(props) {
    const text = props.text;
    const url = props.url;

    return (
        <>
            <span>{" "}<a target="_blank" className="extLink" href={url} rel="nofollow noreferrer">{text}</a></span>
            {" "}
        </>

    )
}


function FigureLabel(props) {
    const num = props.num;

    if (num) {
        return (
            <span className="fig">Figure {num}:{" "}</span>
        )
    }
    return null;
}

function MediaItem(props) {
    const mediaUrl = props.url;
    const label = props.label;
    const description = props.description;


    return (
        <div className="media-wrap">
            <img className="MediaItem" src={mediaUrl} alt={label} data-description={description}/>
        </div>
    )
}


function ShowcaseItem(props) {
    const rootMedia = props.rootMedia;
    const label = props.label;
    const figureNumber = props.figureNumber;
    const baseCssClass = "Media-item";
    const cssClass = (props.cssClass === undefined) ? baseCssClass : baseCssClass + " " + props.cssClass;

    return (
        <li key={rootMedia}>
            <div className={cssClass}>
                <MediaItem url={rootMedia} label={label} description={props.children}/>
                <div className="caption">
                    <FigureLabel num={figureNumber}/>
                    {props.children}
                </div>
            </div>
        </li>
    )
}

function Showcase(props) {
    const title = props.title;
    const mediaOrientation = props.mediaOrientation || "landscape";

    return (
        <section className="showcase">
            <h3 className="showcase-title">{title}</h3>
            <ol className={"hs " + mediaOrientation}>
                {props.children}
            </ol>
        </section>
    )
}

function ProjectMetadata(props) {
    const meta = props.meta;

    const renderGroup = (name, items) => {
        const listItems = items.map((item) => <li key={item}>{item}</li>);
        return (
            <div key={name} className="metaGroup">
                <div className="metaGroupTitle">{name}</div>
                <ul>
                    {listItems}
                </ul>
            </div>
        )
    }
    const groups = Object.keys(meta).map((key) => renderGroup(key, meta[key]));

    return (
        <aside className="meta colLeft">
            {groups}
        </aside>
    )
}

export const cases = [
        {
            name: "construction",
            headerImage: "/images/header-construction.jpg",
            headerTitle: "Improving capital project productivity and profitability by digitizing the job site",
            summaryTitle: "Turning Paper into Gold",
            summaryBody:
                <div>
                    <p>One of the world's largest professions, construction employs 7% of the world's working
                        population while commanding $10 trillion in
                        <ExtLink url=" https://mck.co/3wWa424" text="annual spending"/>. Construction
                        is one of the world's oldest professions, yet astonishingly little has changed
                        in the paper-driven construction process over the past century. When compared to industries like IT,
                        Media, and Finance, where digital transformation has permanently altered the way business is
                        conducted, Construction has remained largely static - relying upon the same people-and-paper
                        intensive processes year after year.</p>
                    <p>Reluctance towards change costs the world $1.6 trillion annually in lost productivity as information
                        is recorded manually, duplicatively and erroneously across multiple systems, spreadsheets, or piles
                        of paperwork in someone' s pickup truck. In 2016, my co-founders and I
                        <ExtLink url="https://bit.ly/3coojTS" text="started Unearth"/> to change how
                        work in the physical world is captured and shared.
                    </p>
                </div>,
            projectMetadata: {
                "roles": ["co-founder", "user research", "ux design", "visual design"],
                "actvities": ["concept validation", "rapid prototyping", "ethnographic research"],
                "duration": ["2016-2018"]
            },
            contentBody:
                <div>
                    <Showcase title="Unearth's OnePlace provides x-ray vision for the job site.">
                        <ShowcaseItem
                            figureNumber="1"
                            label="OnePlace search interface"
                            rootMedia="/images/construction/search-1.jpg"
                        >
                            The Unearth interface provides project
                            stakeholders with x-ray vision into the job site. Here, each dot represents a jobsite
                            activity which can be quickly retrieved with a tap.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="2"
                            label="Text search"
                            rootMedia="/images/construction/search-2.jpg"
                        >
                            Text searches reveal discussions, measurements, and drawings made over the course of the
                            project, making it easy to answer questions months after the project has finished.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="3"
                            label="Cluster disambiguation"
                            rootMedia="/images/construction/search-3.jpg"
                        >
                            Dense activity within a geographic area can make selection difficult for end users. The
                            menu provides users with a way to explore multiple activites at a point.
                        </ShowcaseItem>
                    </Showcase>

                    <Showcase title="OnePlace for Task Delegation and Communication" mediaOrientation="portrait">
                        <ShowcaseItem
                            figureNumber="1"
                            label="Cluster disambiguation"
                            rootMedia="/images/construction/mobile-observation-7.jpg"
                            cssClass="mobileScreen"
                        >
                            Mobile users receive the same powerful tools available to Tablet and Desktop viewers.
                        </ShowcaseItem>

                        <ShowcaseItem
                            figureNumber="2"
                            label="Ergonomic, one-handed control"
                            rootMedia="/images/construction/mobile-observation-1.jpg"
                            cssClass="mobileScreen"
                        >
                            The radial menu affords one-handed, thumb-friendly navigation for core tasks.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="3"
                            label="Beacons"
                            rootMedia="/images/construction/mobile-observation-2.jpg"
                            cssClass="mobileScreen"
                        >
                            Project managers can add a beacon to specific points on the job site, enabling geographic
                            task assignment.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="4"
                            label="Map-driven communication"
                            rootMedia="/images/construction/mobile-observation-3.jpg"
                            cssClass="mobileScreen"
                        >
                            Location-centric discussions are facilitated by common communication metaphors.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="5"
                            label="Notifications"
                            rootMedia="/images/construction/mobile-observation-4.jpg"
                            cssClass="mobileScreen"
                        >
                            After the discussion is started, participatns recieve a notification and location of the task.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="6"
                            label="Field Documentation"
                            rootMedia="/images/construction/mobile-observation-5.jpg"
                            cssClass="mobileScreen"
                        >
                            The participants uploads a photo from the field, including the work done to date.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="7"
                            label="Proof of work"
                            rootMedia="/images/construction/mobile-observation-6.jpg"
                            cssClass="mobileScreen"
                        >
                            Proof of completion is documented and archived via communcation threads easily retrieved later.
                        </ShowcaseItem>


                    </Showcase>

                    <Showcase title="The Minimum Viable Brand for OnePlace V1.0">
                        <ShowcaseItem
                            figureNumber="1"
                            label="Photography"
                            rootMedia="/images/construction/brand-imagery.jpg"
                        >
                            Unearth's earliest customers were responsible for several of the largest capital
                            infrastructure projects of the past century. Photography for the brand was chosen for its
                            ability to evoke scale, immensity, and reliabilty.
                        </ShowcaseItem>

                        <ShowcaseItem
                            figureNumber="2"
                            label="Business Cards"
                            rootMedia="/images/construction/brand-business-cards.jpg"
                        >
                            You know, because physical meetings were a thing before the pandemic.
                        </ShowcaseItem>

                        <ShowcaseItem
                            figureNumber="3"
                            label="Close the Gap"
                            rootMedia="/images/construction/close-the-gap.png"
                        >
                            I enjoyed illustrating a series of isomorphic graphics that could be quickly mixed and matched
                            for sales collateral, bringing consistency between product and marketing.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="4"
                            label="Government Two Pager"
                            rootMedia="/images/construction/gov-2pgr.png"
                        >
                            Flexible 2-pager templates were constructed to quickly respond to new sectors and opportunities.
                            This document helped us explore contract opportunities within the Federal government.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="5"
                            label="Color and buttons"
                            rootMedia="/images/construction/brand-color-buttons.png"
                        >
                            The brand color palette was subject to one significant design constraint: dominant colors needed
                            to provide
                            high contrast against satellite imagery and vector basemaps. Teal became the brand action color
                            due to its infrequency of occurence in satellite imagery.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="6"
                            label="Typography"
                            rootMedia="/images/construction/brand-type-depth.png"
                        >
                            Lubalin became the key headline font for the brand, connoting stability, security and
                            permanence with the classic geometric slab serif form. The Museo Sans family was chosen
                            for its approachability, crisp rendering at small sizes, and high contrast in maps.
                        </ShowcaseItem>

                    </Showcase>

                    <section className="layout3col">
                        <div className="mainColumn">
                            <h2>Background</h2>
                            <p>
                                The first three months of Unearth were spent reading, taking notes and talking to experts
                                and potential customers within the construction field. Our research uncovered three
                                consistent themes which drew our focus towards productivity enhancement in construction:
                            </p>
                            <ol className="keypoints">
                                <li>
                                    <div className="keypointText">
                                        Productivity in the construction sector wasn't just bad - it was getting worse with
                                        time.
                                    </div>
                                    <div className="keypointGraphic">
                                        <img src="/images/construction/stevens-pip-productivity.jpeg"
                                             alt="Put in place construction productivity 1997 - 2011"/>
                                        <div className="caption">
                                            <FigureLabel num="1"/>
                                            2012. Steven's Construction Institute.
                                            <ExtLink
                                                url="https://greghowes.blogspot.com/2012/03/declining-productiving-in-construction.html"
                                                text="Declining productiviy in the construction industry."
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="keypointText">
                                        Construction was ripe with "low hanging fruit" compared to other industries where
                                        digital transformation was already well underway.
                                    </div>
                                    <div className="keypointGraphic">
                                        <img src="/images/construction/mckinsey-digitization-index.png"
                                             alt="Global Digitization Index 2015"/>
                                        <div className="caption">
                                            <FigureLabel num="2"/>
                                            2015. McKinsey Global Instutitute.
                                            <ExtLink
                                                url="https://mck.co/3FB42qQ"
                                                text="Digital America: A Tale of the Haves and Have-Mores"/>
                                        </div>

                                    </div>
                                </li>
                                <li>
                                    <div className="keypointText">
                                        Recent and maturing innovations, such as smart phones, drones, and shoe-box-sized
                                        satellites had unlocked new ways to share information about physical place in real
                                        time.
                                    </div>
                                    <div className="keypointGraphic">
                                        <img src="/images/construction/drone.jpg"
                                             alt="Drone with controller unit"/>
                                        <div className="caption">
                                            <FigureLabel num="3"/>
                                            Consumer technologies had steadily penetrated the job site for the past 8
                                            years. Cheap and accessible remote sensing hardware made it possible to
                                            quantify previously expensive and time consuming tasks.
                                        </div>
                                    </div>
                                </li>
                            </ol>
                            <p>
                                At the end of the three months, we were still a long way from determining what problem our
                                product would solve, but had identified fertile soil where a new venture could grow.
                            </p>

                            <h2> A Solution in Search of a Problem: Building the Product Thesis</h2>
                            <p>In 90 days, we had talked to ~30 experts in the construction sector. My co-founders and I
                                conducted these interviews via phone calls and screenshares. To help us divide and conquer
                                our research tasks, I devised a loose script of questions which gradually increased in
                                structure over time. Early research was free-form and ad-hoc as we explored potential
                                opportunities with our experts. A meeting rarely eneded before showing visual, prototype, or
                                low-fidelity concept I'd designed to help the ideas come to life in front of the customer.
                                These assets often changed with each meeting, as we iteratively revealed common problems in
                                the job site. Within a few weeks, we'd identified two common pain points experienced by
                                general contractors:</p>

                            <ol className="keypoints">
                                <li>
                                    <strong>Collecting photo evidence</strong> of work completed is a critical activity for
                                    general contractors, but largely manual, subject to human error, and difficult to retain
                                    once the project is completed.
                                </li>
                                <li>
                                    <strong>Incremental billing of progress</strong> to the customer is costly and
                                    contentious, especially in projects that involve earthwork (moving soil, building roads,
                                    or other 'horizontal construction' tasks).
                                </li>
                            </ol>

                            <p>
                                The dynamic structure of these early conversations is not always favorably viewed by
                                <ExtLink url="https://amzn.to/3FAVAIg" text="my heroes"/> in the HCI community, but was
                                critical to our success of finding value with the various ideas we explored with them.
                                Though I've conducted and participated in large scale usability studies for Booking.com,
                                Hewlett Packard, and others, Unearth showed me the value of going off-script in early stages
                                of product validation. Using a flexible toolkit of
                                <ExtLink text="investigative techniques" url="https://amzn.to/3DEY7QT"/>
                                {" "}can lead to serendipity and discovery often lost in rigid empirical research studies
                                better
                                suited, in my opinion, for mature products already in market.
                            </p>

                            <h2>Where does it hurt? Pain and Opportunity</h2>
                            <p>
                                At the conclusion of our research period, I distilled our findings into report summarizing
                                our findings. These findings would provide the backbone for the product we would create over
                                the next two years.
                            </p>
                        </div>
                    </section>

                    <Showcase title="Customer Pain on the Job Site">
                        <ShowcaseItem
                            label="OnePlace search interface"
                            rootMedia="/images/construction/need_0007_photos.png"
                        >
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Text search"
                            rootMedia="/images/construction/need_0006_measurements.png"
                        >
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Mobile usage"
                            rootMedia="/images/construction/need_0005_mobile.png"
                        >
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="The Need for Collaboration"
                            rootMedia="/images/construction/need_0004_collaboration.png"
                        >
                        </ShowcaseItem>
                    </Showcase>

                    <section className="layout3col">
                        <div className="mainColumn">
                            <h2>Who is the customer?</h2>
                            <p>
                                The question "who is the customer" will impact nearly every existential decision made in an
                                early stage company. The answer may change over time, as it certainly did at Unearth, but
                                without a shared vision of who the product will benefit, internal teams will inevitably
                                drift towards their own biases and volitions. The customer persona can be an invaluable tool
                                in preventing this drift, but the cost of improper execution can be large.
                            </p>
                            <p>
                                One of the greatest risks when developing a customer persona is the emergence of
                                self-referential design: where personal or team bias is falsely represented as fact.
                                Self-referential design can occur when founders, product managers, designers, etc., project
                                their own goals, motivations and skills onto the customer. In such case, a Persona is only
                                useful in understanding one person's mental model. To avoid this risk, it was important to
                                me our initial personas were derived from evidence obtained from the customer, in their own
                                words whenever possible. I leveraged the effective persona development framework proposed by
                                <ExtLink
                                    text="Cooper, Reimann, Cronin, and Noessl"
                                    url="https://amzn.to/3DSjNJk"/> to guide my process.
                            </p>
                            <p>
                                Leveraging the question bank I'd developed over our user interviews, I constructed user
                                personas of for our two primary customer types: General Contractor Owners, and Project
                                Managers and identified two supplemental customer types for Architect and Civil Engineer,
                                which we found were not potential customers of our product, but important in our general
                                understanding of our customer's ecosystem.
                            </p>
                        </div>
                    </section>
                    <Showcase title="Construction Project Personas">
                        <ShowcaseItem
                            label="General Contractor Executive"
                            rootMedia="/images/construction/persona-gc-exec.jpg"
                            figureNumber="1"
                        >
                            The General Contractor Executive is the most financially incentivized persona that benefits
                            from greater productivity and the ultimate buyer of the software. However, the Exec must
                            win buy in from his or her team in order to make any change in process stick.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="General Contractor Project Manager"
                            rootMedia="/images/construction/persona-project-manager.jpg"
                            figureNumber="2"
                        >
                            The Project Manager is usually one of the most influential roles on the job site and ultimately
                            accountable for ensuring their projects are completed on-time and on-budget. Effective PM's earn
                            trust and their discipline leads each day on the job. They will most likely take the most heat
                            if a new tool causes disruption from the status quo.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Civil Engineer"
                            rootMedia="/images/construction/persona-civil-engineer.jpg"
                            figureNumber="3"
                        >
                            Civil engineers play a vital role in horizontal construction projects (roads, bridges, etc)
                            by transforming their highly technical knowledge into instructions that someone with a
                            high school education can execute. CE's draft paper specifications that are vital to the
                            job site, though are not direct users of Unearth software.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Architect"
                            rootMedia="/images/construction/persona-architect.jpg"
                            figureNumber="4"
                        >
                            Architects, while critical to vertical construction projects (buildings, campuses, etc),
                            rely on closed software ecosystems whose moats have made digital collaboration
                            between field and office costly and difficult. We found that Architects were unlikely to
                            find value from field collaboration with Unearth.
                        </ShowcaseItem>
                    </Showcase>

                    <section className="layout3col">
                        <div className="mainColumn">
                            <h2>How will customers interact with our Product? The Core Four Behaviors</h2>
                            <p>
                                Through our contextual interview process, we spoke with general contractors and land owners
                                from across the country. With our desire to build a horizontal platform capable of scaling
                                to multiple scenarios, we had inadvertantly created a new problem in our ability to solve
                                any one specific customer use case. My task was to define how user-centric design might span
                                the inescapable differences between building a skyscraper and constructing a highway. The
                                resulting mental model should be strong enough to remain true years later, yet flexible
                                enough to apply to use cases.
                            </p>
                            <p>
                                Though the type of customer changed in 2019, the type of activities have remained largely
                                the same, bringing continuity to product during market pivots.
                            </p>
                        </div>
                    </section>

                    <Showcase title="The Core 4 Behaviors">
                        <ShowcaseItem
                            label="The Core Four"
                            rootMedia="/images/construction/core-four.png"
                            figureNumber="1"
                        >
                            The four key behaviors users will execute in OnePlace.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Creating an Observation"
                            rootMedia="/images/construction/core-observe.png"
                            figureNumber="2"
                        >
                            Observations from the field form Unearth's core value to its customers. This flow demonstrates
                            how a mobile user records a physical observation and shares it with their team.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Creating a Measurement"
                            rootMedia="/images/construction/core-measure.png"
                            figureNumber="3"
                        >
                            Measurements allow office teams to verify work completed without human assistance.
                        </ShowcaseItem>
                    </Showcase>

                    <section className="layout3col">
                        <div className="mainColumn">
                            <h2>How can Design help our customers? Design Principles for Unearth</h2>
                            <p>
                                A principle serves as a fundamental truth upon which a greater system of belief and behavior
                                may be constructed. As a designer, I am drawn towards design principles which change
                                infrequently compared to aesthetic styles and modes of input so often disrupted by
                                innovation and trend.
                            </p>
                            <p>
                                Design principles become particularly relevant when envisioning the constraints of how one's
                                product will be used. As example, my first iterations of the product in relied upon
                                dark-themed user interface elements, drawing the user's eye towards map and data
                                visualizations (similar to graphics editing programs like Photoshop or Final Cut).
                            </p>
                        </div>
                    </section>

                    <Showcase title="Measurements: Before Design Principles">
                        <ShowcaseItem
                            label="Dark Theme Measurements"
                            rootMedia="/images/construction/dark-1.jpg"
                            figureNumber="1"
                        >
                            Initial user interface iterations relied on dark thematic colors, which turned out to be
                            difficult to read in the field. In this screen, the user can identify a section of land
                            to measure - determining the change in volumne over two periods of time.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="The Survey Timeline"
                            rootMedia="/images/construction/dark-2.jpg"
                            figureNumber="2"
                        >
                            The user is presented with a timeline showcasing dates when observation data is available.
                            In this instance, six drone surveys occurred before October 20th. A process known as
                            photogrammetry is used to create a 3D measurement of the land at each survey date.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Seeing change in four dimensions"
                            rootMedia="/images/construction/dark-3.jpg"
                            figureNumber="3"
                        >
                            A common question asked by Unearth customers is "how much 'cut' or 'fill' has changed
                            since we last billed the customer?" As horizontal construction companies bill on earth moved,
                            this capability allowed them to take accurate measurements without paying a surveyor.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Dark Theme volumetric measurements"
                            rootMedia="/images/construction/dark-4.jpg"
                            figureNumber="4"
                        >
                            Users can quickly visualize how much has changed between two drone surveys. The ability
                            to 'cut' allows the user to see how much earth has been taken away, while 'fill' allows
                            the opposite.
                        </ShowcaseItem>
                    </Showcase>

                    <section className="layout3col">
                        <div className="mainColumn">
                            <p>
                                It may have looked cool, but it wasn't usable by our customers. As we discovered the
                                importance of our product in the field, I was heavily influenced by research conducted
                                by <ExtLink text="O'Day and Tijerina"
                                            url="https://saemobilus.sae.org/content/2011-01-0597/"/> on behalf of the Ford
                                Motor Company, who'd invested over decades to understand how sunlight can effect legibility
                                in typography and user interfaces. While satellite imagery can contrast against a black
                                background, text and numbers will not.
                            </p>
                            <p>
                                Design principles provided our team with a shared agreement of what "good design" might look
                                like at Unearth. By proposing, debating, and adopting design principles as a team, we were
                                able to reduce the number of possible designs and execute upon new ideas quickly.
                            </p>
                        </div>
                    </section>

                    <Showcase title="Design Principles at Unearth">
                        <ShowcaseItem
                            label="The Power of Constraints"
                            rootMedia="/images/construction/constraints.jpg"
                            figureNumber="1"
                        >
                            This quote from one of the great mid-century designers, Charles Eames, helped our team
                            remain grounded as we collectively defined what "good design" meant for a company whose product
                            visualized maps on electronic devices outdoors
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Embracing Constraints"
                            rootMedia="/images/construction/empower.png"
                            figureNumber="2"
                        >
                            When design principles are collectively identified and agreed upon, the role of "designer"
                            is no longer limited by title. These constraints helped the team identify the formula enabling
                            engineers, marketers, salespeople, customer success, etc, to participate in building good
                            design.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Sunlight"
                            rootMedia="/images/construction/sunlight.png"
                            figureNumber="3"
                        >
                            Sunlight can make reading text from a screen difficult. I helped automate our design process
                            by running product screens through a contrast analyzer that would complain when contrast
                            fell below an <ExtLink text="'AA' rated threshold" url="https://usecontrast.com/guide"/>.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Time and Density"
                            rootMedia="/images/construction/time-density.png"
                            figureNumber="4"
                        >
                            Density is often at odds with effective cartographic design - especially when developing
                            large, touch-friendly user interface elements. In early iterations, elements were filtered
                            on the map by recency. As observations age, their size on the map diminished, helping
                            keep maps legible.
                        </ShowcaseItem>
                    </Showcase>

                    <Showcase title="Measurements: After Design Principles">
                        <ShowcaseItem
                            label="Activity Feed Makeover"
                            rootMedia="/images/construction/afterd-activity.jpg"
                            figureNumber="1"
                        >
                            The interface was redesigned for legibility in the field. The first overhaul came with in
                            a contrast overhaul with the activity feed. Important text was now consistently displayed
                            at high contrast with a white background that worked well with sunlight.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Delta Measurements"
                            rootMedia="/images/construction/afterd-delta.jpg"
                            figureNumber="2"
                        >
                            Volumetric text measurements were redesigned for high contrast of text elements. Feedback from
                            customers indicated 3D visualization was a nice-to-have, but not necessary for providing
                            value, so the 3D spinning plate had to go.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="The Knife Tool"
                            rootMedia="/images/construction/afterd-knife.jpg"
                            figureNumber="3"
                        >
                            Office users have different environments than the field and the Knife tool was created
                            especially for them. Civil engineering schematics often utilize cross-section diagrams, allowing
                            the reader to understand 3D characterstics in two dimensions. The knife tool allows a user to
                            slice into
                            an arbitrary piece of the job site.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Visualizing Cut and Fill in 2D"
                            rootMedia="/images/construction/afterd-cutfill.jpg"
                            figureNumber="4"
                        >
                            Made for users in an office setting, the knife tool exposes cut and fill graphics showing
                            what has been added and what has been taken away between two surveys.
                        </ShowcaseItem>
                    </Showcase>


                    <section className="layout3col">
                        <div className="mainColumn">
                            <h2>How we got there</h2>
                            <p>
                                If you've read this far (thank you!), you may be more interested in exploring
                                more of the process than what I shared above.
                                Below are some of the key "thought artifacts" that helped
                                our team attain consensus and build great product.
                            </p>

                            <p>
                                Product development is a team sport and the inherent nature of a portfolio
                                can lead to more <em>I's</em> than <em>we's</em>. It's important to note that nothing
                                displayed within this case study occurred in a vaccum, nor without significant
                                collaboration with Engineering, Product Management, Customer Success, and Sales.
                            </p>
                            <p>
                                The following are some of my favorite sketches and drafts from my early Unearth days.
                            </p>
                        </div>
                    </section>

                    <Showcase title="Sketches and exploration">
                        <ShowcaseItem
                            label="Asset Menus"
                            rootMedia="/images/construction/sketch-asset-popover.png"
                            figureNumber="1"
                        >
                            Quick sketches like this helped the team iterate on new ideas for interaction.
                        </ShowcaseItem>

                        <ShowcaseItem
                            label="Entering a building"
                            rootMedia="/images/construction/sketch-building.png"
                            figureNumber="2"
                        >
                            Investigating how the application should flow when the user enters the jobsite.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="The Resolution Loop"
                            rootMedia="/images/construction/sketch-resolution-loop.png"
                            figureNumber="3"
                        >
                            The resolution loop explains how technicians would use Unearth during an onsite building
                            inspection
                        </ShowcaseItem>

                        <ShowcaseItem
                            label="Map Symbology Study"
                            rootMedia="/images/construction/sketch-dot-study.png"
                            figureNumber="4"
                        >
                            Mixed fidelity sketching helped identify colors and and symbology that would
                            stand out given different satelite imagery and maps
                        </ShowcaseItem>


                    </Showcase>

                    <section className="layout3col">
                        <div className="mainColumn">
                            <h2>Next...</h2>
                            <p>
                                Continue the story as we pivot to the <Link to="/utilities">Utility sector</Link>.
                            </p>
                        </div>
                    </section>
                </div>
            ,
        },
        {
            name: "utilities",
            headerImage: "/images/utilities/header-san-bruno.jpg",
            headerTitle: "Modernizing critical infrastructure inspection",
            summaryTitle: "Increasing Safety through Data Transparency",
            summaryBody:
                <div>
                    <p>It's 6pm in September 2010, when a
                        <ExtLink text="gasline explosion" url="https://en.wikipedia.org/wiki/San_Bruno_pipeline_explosion"/>
                        {" "}
                        rocks a small community in San Bruno, California. The engulfing fire claims the lives of 8 people
                        and destroys 30 homes.
                        After several years of investigation and $8 billion in fines, the root cause of the
                        accident emerged:
                        <ExtLink text="innaccurate geographic data"
                                 url="https://www.sfgate.com/news/article/PG-E-ignored-gaps-in-data-engineer-says-3752181.php"/>.

                    </p>

                    <p>All of this was top of mind for Pacific Gas & Electric construction manager when he attended a demo
                        by
                        an Unearth
                        sales associate in 2018. The demo revealed a new use case for Unearth's OnePlace
                        software, changed Unearth's commercial
                        focus from General Contractors to Utility companies, and helped Unearth successfully raise a Series
                        A venture round in 2019.</p>

                    <p>This is the story of how user centered design helps Utility companies make gas infrastructure safer
                        for millions of customers across the United States every day.</p>
                </div>,
            projectMetadata: {
                "roles": ["ux designer", "visual designer", "design manager", "data engineer"],
                "actvities": ["rapid prototyping", "ethnographic research", "data visualization"],
                "duration": ["2018-2021"]
            },
            contentBody:
                <div>
                    <Showcase title="The Customer Pivot from General Contractor to Utility">
                        <ShowcaseItem
                            figureNumber="1"
                            label="Toolkits"
                            rootMedia="/images/utilities/inspection-flow.jpg"
                        >
                            Users can quickly document observations from the field by opening their toolkit. Toolkits
                            can be customized per inspection use case, making it easy for utilities to define
                            inspection workflows for sewers, powerlines, or any other type of infrastructure asset. This
                            was the final shipped product after validating my prototypes with customers.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="2"
                            label="Dashboard"
                            rootMedia="/images/utilities/dashboard-assembled.png"
                        >
                            This Dashboard allows executive management at a utility to view insepction status
                            across all of its customers. In addition to answering the key question, "how many addresses
                            have we cleared this quarter", it provides the utility with a clear view of
                            efficiency across all employed contractors.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="3"
                            label="Territory view"
                            rootMedia="/images/utilities/territory-map.jpg"
                        >
                            Territory Map View enables utility companies and contractors to view the entire inspection
                            history of a region
                            and drill into specific address-level detail of individual properties.
                            I designed this early prototype collaboratively with PG&E to reveal the key functionality
                            required to enable utilty construction managers to report to their management teams on progress.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="4"
                            label="Territory view Parcels"
                            rootMedia="/images/utilities/territory-map-parcels.jpg"
                        >
                            By filtering the map to display only Parcels (legal property boundaries), utilities can quickly
                            identify
                            the progress of inspections taking place throughout the community.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="5"
                            label="Territory view Status Filtering"
                            rootMedia="/images/utilities/territory-map-parcel-filtering.jpg"
                        >
                            With OnePlace, utilities can answer "where are my crews inspecting now" in seconds
                            instead of calling or emailing each contractor for status.
                        </ShowcaseItem>
                    </Showcase>
                    <section className="layout3col">
                        <div className="mainColumn">
                            <h2>Background</h2>
                            <p>
                                In the mid 1980’s, directional drilling gave rise to a dangerous and unexpected problem: gas
                                line cross bores, in which a gas line is accidentally drilled through a sewer line. Ruptured
                                gas line cross bores
                                repeatedly cause deadly explosions
                                <ExtLink text="nationwide"
                                         url="https://en.wikipedia.org/wiki/Merrimack_Valley_gas_explosion"/>.
                                The federal government esimates
                                <ExtLink
                                    text="one undiscovered cross bore exists for every 2.5 miles of gas pipeline in the US"
                                    url="https://www.ncsl.org/research/energy/cross-bore-prevention.aspx"/>.

                                To ensure their customer homes and businesses are free of cross bores, utility companies
                                are required by the federal goverment to visually inspect the sewer infrastructure
                                surrounding
                                customer address.

                                Prevention of human loss and billions in damages has spurred the nation's largest utilities
                                to modernize the paper-driven processes responsible for fatal data innaccuracies.
                            </p>

                            <p>
                                In 2016, Unearth's OnePlace
                                <ExtLink text="construction management product was released"
                                         url="/#/unearth-construction"/>,
                                {" "}allowing field crews to build detailed maps of jobsites from the field, from any
                                device.
                                When we were introduced to Pacific Gas & Electric in 2018,
                                I suspected that with several modifications to the core platform OnePlace could be used to
                                catalog the
                                history of gas inspections for utilities across the country. The next step was to
                                validate the hypothesis by better understanding how inspections were dispatched and
                                executed.
                            </p>

                            <p>
                                The next two months were spent observing utility employees in the office and their
                                subcontractors
                                in the field to understand the inspection process from end-to-end.
                            </p>
                        </div>
                    </section>

                    <Showcase title="Understanding the customer process">
                        <ShowcaseItem
                            figureNumber="1"
                            label="Field Inspections"
                            rootMedia="/images/utilities/research-composite-field.jpg"
                        >
                            Cross bore inspections require running a camera the length of the pipeline to ensure
                            no intrusion has taken place. Mainline sewer inspections utilize a remote operated
                            robot to crawl through segments of sewer pipe.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="2"
                            label="Contractor office"
                            rootMedia="/images/utilities/research-composites-office.jpg"
                        >
                            When the field inspection is finished, the files are taken back to the office where
                            the data is cleansed. Here, paper maps are cross-referenced with Excel spreadsheet logs.
                            Discrepencies across multiple records are common, requiring multiple rounds of expensive, manual
                            quality control.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="3"
                            label="Preparing the Inspection Package"
                            rootMedia="/images/utilities/qaqc.jpg"
                        >
                            Before the inspection can be turned over to the utility, it must undergo an extensive,
                            manual quality assurance review cycle. Here an employee is reviewing an inspection map
                            in between burning inspection data to DVD.
                        </ShowcaseItem>
                    </Showcase>

                    <section className="layout3col">
                        <div className="mainColumn">

                            <h2>Identifying the problem</h2>
                            <p>
                                Utility companies across the US are actively engaged in inspecting their gas infrastructure
                                for the presence of potentially
                                fatal cross bores. Across the utilty sector, legacy cross bore inspections follow this
                                general process:
                            </p>

                            <ol>
                                <li>The Utility identifies residential and commercial addresses most at risk for cross
                                    bores
                                </li>
                                <li>The Utility hires a Subcontractor to inspect all sewer infrastructure connected to the
                                    address
                                </li>
                                <li>The Subcontractor duplicatively documents their inspection with hand-drawn maps,
                                    screenshots of Google Maps, multiple spreadsheets and
                                    video inspection
                                </li>
                                <li>The Utility company reviews Subcontractor inspection deliverables through its Quality
                                    Assurance / Quality Control processes
                                </li>
                                <li>A subset of the inspection information is published to the Utility's Geographic
                                    Information System (GIS) of record. Inspection videos are burned to DVD and
                                    mailed to the utility.
                                </li>
                                <li>Go to first step</li>
                            </ol>

                            <div className="keypointGraphic">
                                <img src="/images/utilities/inspection-data-journey.png" alt="Inspection Data Journey"/>
                                <div className="caption">
                                    In order to understand how data flows from contractor to utility, I created this
                                    diagram to help our team underand how data was mutated from origin to destination.
                                </div>
                            </div>

                            <p>
                                Several fundamental problems with this process all but ensured innacurracies in data
                                collection.
                            </p>
                            <ul>
                                <li>
                                    <strong>Data Entry Error:</strong> The duplication of data across multiple documents
                                    provided the opportunity for data entry errors as inspectors entered observations in
                                    multiple places. Both subcontractors and utility alike spend hundreds of hours each
                                    month reviewing duplicative data captured for each address searching for anomolies.
                                </li>
                                <li>
                                    <strong>An incomplete system of record:</strong> Because utilities often use a variety
                                    of inoperable tools, only a subset of the inspection data is persisted to the final
                                    system of record.
                                    The contractor inspection package includes invaluable sewer scope videos which serve as
                                    evidence that the line has been successfully cleared by the utility. Most utilities
                                    store these videos in long term physical storage, like DVDs, which can take days to find
                                    when a dispute occurs.
                                </li>
                                <li><strong>Slow reaction time in emergency situations:</strong>
                                    Inspection videos and photos provide invaluable evidence when the utility is accused of
                                    failing to remove the cross bore by a municipality, water authority, or another
                                    subcontractor years later. Beacuse the system of record contains a subset of the
                                    inspection data, requests to provide evidence of successful inspection can take weeks as
                                    the original inspection documents and videos are retrieved from physical storage.
                                </li>
                            </ul>

                            <h2>The Design Process</h2>
                            <p>
                                After better understanding the problems facing utilities and their contractors, I took my
                                findings and created a series of paper and interactive prototypes that could be reviewed
                                with PG&E as well as other utility customers.
                            </p>

                            <p>
                                The experience was quickly sketched out and reviewed with customer support
                                and engineering. After a candidate design was reviewed internally, it was then
                                turned into an interactive prototype and reviewed with two different customer sets:
                                the utility and the hired subcontractor.
                            </p>

                        </div>

                    </section>

                    <Showcase title="Prototyping the Experience">
                        <ShowcaseItem
                            figureNumber="1"
                            label="Pipeline tool"
                            rootMedia="/images/utilities/inspection-pipeline-tool.jpg"
                        >
                            One of the most important user flows to define was the Pipeline tool allowing users
                            to document the presence of a sewer or storm water line on the map.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="2"
                            label="Inspection Form"
                            rootMedia="/images/utilities/inspection-status.jpg">
                            Once the line has been added to the map, the user can quickly notate whether
                            the end points of the line inspection were performed successfully. If not, the
                            access point is labelled as "Unable to Access" (UTA) and will be reviewed later.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="3"
                            label="Starting an inspection from another asset"
                            rootMedia="/images/utilities/lateral-from-manhole.jpg">
                            In order to save user time, the system provides a way for users to start a new inspection
                            from a previous inspection. The design saves user time by preventing duplicate data
                            entry while keeping the user within the context of the map.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="4"
                            label="Video Inspection Storyboard"
                            rootMedia="/images/utilities/prototype-video.jpg">
                            This storyboard demonstrates how a utility user can review a subcontractor map and inspection
                            videos. Specific issues are indicated on both the map and the video timeline to help
                            users quickly skip to the most important information.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="5"
                            label="Neighborhood View"
                            rootMedia="/images/utilities/prototype-area-view.jpg">
                            This prototype of a Neighborhood View informs subcontractors on the location and status
                            of inspections in their assigned area. The toolkit panel allows users to quickly add new
                            features and issues as they are discovered.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="6"
                            label="Neighborhood View"
                            rootMedia="/images/utilities/prototype-add-asset.jpg">
                            This prototype of a Neighborhood View informs subcontractors on the location and status
                            of inspections in their assigned area. The toolkit panel allows users to quickly add new
                            features and issues as they are discovered.
                        </ShowcaseItem>
                    </Showcase>

                    <section className="layout3col">

                        <div className="mainColumn">

                            <h2>Results</h2>

                            <h3>PG&E Results</h3>

                            <ul>
                                <li>
                                    <strong>375% Return on Investment:</strong> PG&E realized a significant return
                                    as it streamlined its assignment, inspection, and reporting process.
                                </li>
                                <li>
                                    <strong>Increased rate of inspection by 46%:</strong> As a result of reduced
                                    duplication,
                                    contractor field teams are able to perform more inspections than before using Unearth.
                                </li>
                                <li>
                                    <strong>Reduced contractor operation cost by 37%</strong>. As a result of the new
                                    system, contractors significantly reduced the cost to perform an inspection, giving
                                    them an advantage over contractors not using Unearth.
                                </li>
                                <li>
                                    <strong>56 year reduction in project timelines</strong>. Because of the significant
                                    improvements in efficiency, PG&E reduced its inspection queue by 56 years.
                                </li>
                            </ul>

                            <p>
                                Using Unearth’s software to create maps, track inspections, and share data, PG&E has been
                                able to streamline their cross bore mapping process from the top down. Subcontractors can
                                now cover up to 66% more area in a single day, and productivity has improved throughout the
                                operation. Instead of manually entering data into multiple systems, PG&E and it’s
                                subcontractors now have one system of record.
                            </p>

                            <h3>Industry Results</h3>

                            <p>
                                These results have been replicated by several of the nation's largest utility companies
                                who now rely on OnePlace to document infrastructure every day. Regional utilities such
                                as Duke Energy, Eversource, Nisource, and others have achieved the following results
                                after adopting Unearth's OnePlace solution:
                            </p>

                            <ul>
                                <li><strong>209% Return on Investment</strong></li>
                                <li><strong>54% increased contractor efficiency</strong></li>
                                <li><strong>20 year reduction in project timelines</strong></li>
                            </ul>

                            <h2>Conclusion</h2>

                            <p>
                                The customer pivot from general contractor to utility company brought two significant
                                changes to Unearth. First, after three years of business, Unearth was able to acheive
                                product market fit within the utility sector - collecting some of the largest and most
                                reputable utilities in the US as customers. Second, the additional traction
                                propelled
                                <ExtLink url="https://bit.ly/3K1lXtW" text="Unearth's Series A fundraising"/>, helping
                                us raise another $7M for the growth of the company.
                            </p>

                            <p>
                                At the heart of this change was our team's laser focus on the need of the user. By
                                helping utility companies and their subcontractors reduce error rates and improve
                                efficiencies, we've helped reduce the chance that another tragedy like the San
                                Bruno pipeline explosion happens again.
                            </p>


                            <h2>Next...</h2>
                            <p>
                                Return to <Link to="/">Case Studies</Link>.
                            </p>
                        </div>


                    </section>
                </div>
        },
        {
            name: "hotels",
            headerImage: "/images/hotels/header.jpg",
            headerTitle: "Designing for 50x customer growth",
            summaryTitle: "Man-in-the-middle Design",
            summaryBody:
                <div>
                    <p>
                        In the early 2000’s, online travel agencies (OTAs) were eating the world. The superior user
                        experience of OTA brands like Expedia, Travelweb, and Booking shifted profit from hotelier to OTA.
                        As the volume of online bookings increased, so too did the commission rates imposed upon hotels, bed
                        and breakfast owners, and other such “room sellers.” The strong desire of room sellers to sell their
                        products directly to consumers, with low-or-no commission, created a wave of hospitality tech
                        companies eager to help. Founded in 2010, buuteeq empowered room sellers to create their own
                        distinctive, branded website with low-to-no code. When I joined in 2012, we aspired to have 3,000
                        customers. When I left in 2016, we had 150,000 customers following acquisition by the Priceline
                        Group (AKA Booking.com). This is my story of being in the middle: betwixt room seller and tech
                        behemoth.
                    </p>
                </div>,
            projectMetadata: {
                "roles": ["ux designer", "design manager", "user researcher"],
                "actvities": ["rapid prototyping", "ethnographic research"],
                "duration": ["2012-2016"]
            },
            contentBody:
                <div>
                    <Showcase title="Powerful tools for Hoteliers from Booking.com">
                        <ShowcaseItem
                            figureNumber="1"
                            label="Login"
                            rootMedia="/images/hotels/login.jpg"
                        >
                            A dramatic departure from the previous Booking.com hotel partner login experience, the
                            BookingSuite customer portal utilized, easy-to-read form controls
                            and balanced imagery to improve the approachability of the brand to customers.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="2"
                            label="Metrics"
                            rootMedia="/images/hotels/dash-basic.jpg"
                        >
                            Upon logging into their control panel, free-tier hoteliers are provided with basic
                            statistics on the performance of their BookingSuite website. Hoteliers could upgrade their
                            subscription to see richer data insights and trend analysis.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="3"
                            label="Easy Content Editing"
                            rootMedia="/images/hotels/page-editor.jpg"
                        >
                            BookingSuite provides hoteliers with an easy to use content management system, making it
                            effortless to publish a responsive, mobile-friendly website in seconds. This was still
                            novel in 2014.
                        </ShowcaseItem>
                        <ShowcaseItem
                            figureNumber="4"
                            label="Flexible content design"
                            rootMedia="/images/hotels/home-experiences.jpg"
                        >
                            Two examples of luxury hotel brands websites published using the BookingSuite content management
                            system. The flexibility of the product made it possible for luxury chains, botique hotels,
                            and bed & breakfasts, to bring their brands to life and increase direct bookings without
                            commission.
                        </ShowcaseItem>
                    </Showcase>

                    <section className="layout3col">
                        <div className="mainColumn">

                            <h2>Background</h2>
                            <p>
                                In 2012, buuteeq sold essentially two products: a no-code website builder and a v1 booking
                                engine. This lean booking engine pushed a web form into a room seller’s Property Management
                                System (PMS). Strategically, our business wanted to replace the proprietary, costly PMS with
                                a superior product at a dramatically lower cost to the room seller. A ground-up PMS design
                                is a serious undertaking - and wickedly fun. In these early days, my job was to help the
                                room seller regain their lost profit margin through better user experiences. Minimizing
                                friction between the divergent motivations of website customers and small business owners
                                was an enjoyable, complex and continual design challenge.
                            </p>

                            <h2>Building the Website Builder</h2>
                            <p>
                                My first assigned was to design several new visual skins for room seller websites. The
                                constraints of my design environment were defined by the data schema our BackOffice product
                                supported. Documentation was non-existent. APIs were a twinkle in the eye. I loved it. My
                                job was a blend of front-end engineering and visual design as I helped create two
                                distinctive, fully responsive website skins we called “blueprints.”
                            </p>


                            <p>
                                A blueprint defined the design aesthetic, size and placement of booking buttons, and general
                                information design. The gold standard for the effectiveness of a blueprint was a metric we
                                called the visit-to-book rate. It was simple to measure: the percentage of visitors who
                                reach the website and ultimately book a room. It was brutally honest, but only when
                                neglecting to admit the level of achievable statistical significance and complexity inherent
                                in multivariate comparison.
                            </p>

                            <p>
                                I created two blueprints in my first months on the job. The first, named Touchtile, was a
                                Windows Mobile-inspired grid experience, which turned out popular with gen Z testers, but
                                very few others. The second blueprint I created was named Boulevard, which became buuteeq’s
                                highest-earning and most widely used design.
                            </p>

                            <p>
                                Boulevard was our first fully responsive blueprint. At this time, tools for building
                                responsive websites were limited and browser support was limited, requiring costly technical
                                workarounds to achieve uniform experiences. I loved the challenge of designing and then
                                implementing responsive designs. My prior experience as an engineer allowed me to contribute
                                to front end engineering tasks as well as developing our pattern library.
                            </p>

                        </div>
                    </section>

                    <Showcase title="Boulevard: the Responsive Hotel Blueprint">
                        <ShowcaseItem
                            label="Opus Hotel Homepage"
                            rootMedia="/images/hotels/opus1.jpg"
                            figureNumber="1"
                        >
                            The Boulevard design blueprint utilized large bands of content that responsively filled the
                            user's device. Room sellers could customize the look and feel of their site to match
                            the unique aspect of their brands.
                        </ShowcaseItem>

                        <ShowcaseItem
                            label="Opus Hotel Interiors"
                            rootMedia="/images/hotels/opus2.jpg"
                            figureNumber="2"
                        >
                            An example of the Room selection and Photo Library Screens. The photos page was critical
                            in influencing the user Visit to Book ratio.
                        </ShowcaseItem>

                        <ShowcaseItem
                            label="Variation"
                            rootMedia="/images/hotels/boulevard-sites.jpg"
                            figureNumber="3"
                        >
                            Boulevard could easily adapt between light and dark color palettes, helping ease the pain
                            of onboarding new customers seeking a professional looking design.
                        </ShowcaseItem>
                    </Showcase>

                    <section className="layout3col">
                        <div className="mainColumn">


                            <h2>Building a Rate Management MVP for Small and Large Room Sellers</h2>
                            <p>
                                My second project at buuteeq was one of my favorites in my career in terms of scope and
                                complexity. Design a hotel rate management system that can be used by an experienced hotel
                                operator or a novice bed and breakfast owner. The challenge could be concentrated to these
                                three constraints:
                            </p>

                            <ol>
                                <li>The system will be used as little as once a year to define a range of rates, or it
                                    can be used multiple times per day to allow the room seller to capitalize on supply and
                                    demand
                                </li>
                                <li>
                                    The system should contain the most widely used features in enterprise Property
                                    Management Systems (PMSs) that took decades to develop.
                                </li>
                                <li>
                                    Release the minimum viable product to buuteeq customers in less than a year
                                </li>
                            </ol>

                            <p>
                                Of these constraints, number one was by far the most difficult to satisfy. In my interviews
                                with hoteliers I found a gulf between the day-trader-like hotel operators in downtown hotels
                                satisfying hundreds of guests weekly and the mom-and-pop bed and breakfast that hosted 4-12
                                guests per week. While each group wanted to sell rooms on a website, they went about their
                                business in very different ways.
                            </p>

                            <p>
                                How does a designer satisfy both user types? Is this even possible? With strong product
                                strategy and an acknowledgement that you won’t follow a straight line to reach your goal,
                                achieving both becomes possible.
                            </p>

                            <p>
                                One strategy that helped immensely for focusing my designs during this period was focusing
                                on the “intermediate user” of our product. The idea of
                                <ExtLink url="https://flylib.com/books/en/2.153.1.26/1/"
                                         text="Optimizing for Intermediates"/>
                                is simple. As customers learn your product,
                                they will spend a short time being experts. Very few of your customers will become experts.
                                The great majority of your customers spend their time as intermediates – somewhere in the
                                middle between n00b and poweruser. The idea is simple, but the design implications are
                                profound. By focusing user experience design efforts on intermediate users, one can avoid
                                wasting valuable screen real estate to explain every feature like the user is brand new,
                                while also avoiding catering to the obscure and highly specific desires of technically
                                advanced, muscle-memory users that memorize every keyboard shortcut. This strategy can be
                                appropriate for situations where switching costs to alternative software is high and
                                customers are incentivized to learn the software. It’s a less effective strategy when the
                                product must hook the user within the first 30 seconds or risk losing them forever.
                            </p>

                            <div className="keypointGraphic">
                                <h3>User Questions based on their Skill with the Product</h3>
                                <img src="/images/hotels/intermediates.jpg" alt="Designing for intermediates"/>
                                <div className="caption">
                                    When interface space is limited, and self-service is a requirement, it can be
                                    helpful to design for the most common skill level of your users.
                                </div>
                            </div>


                            <h2>Splitting the design problem in two: Rate Setup and Rate Management</h2>
                            <p>
                                The solution to our design problem was solved by careful observation of our customers. Our
                                customers taught me the basics of rate management – or how to create a thoughtful schedule
                                or room rates for a year, given several assumptions about forecasted demand, competitor
                                activity, and the minimum acceptable rate for a room.
                            </p>

                            <p>
                                I learned that most room sellers used a concept of a “Best Available Rate” which would
                                define a range of rates per night for their rooms. For instance, during busy season, a
                                hotelier would set their property at level 5 of 10, where level 10 is the most expensive
                                nightly rate they might apply to a room. During busy season, a hotel might use level 8,
                                while during shoulder or low seasons, the hotel might use level 1 or 2. If a high-profile
                                sporting event were coming in town next month, that week might be applied a level 10 and
                                return to a level 4 at the end of the week.
                            </p>

                            <p>
                                One of the primary differences in rate management strategy between large and small
                                hospitality organizations is the frequency at which they adjust their rates. Hospitality
                                owners in less competitive markets often defined a seasonally defined rate schedule for high
                                and low seasons. In more competitive markets, sophisticated rate management strategists
                                looked at their competitor rates multiple times per day, across many different websites, and
                                used promotions such as last-minute booking, stay-X-nights-get-Y-free to double their
                                revenue when compared to using a static rate management strategy.
                            </p>

                            <p>
                                This separation in behavior led to a natural distinction in our products:
                            </p>

                            <ol>
                                <li><strong>Rate Setup</strong> - was the application used by small and large room sellers
                                    to define a schedule of rates for each room type, for the entire year
                                </li>
                                <li>
                                    <strong>Rate Planner</strong> - was the premium application used by large room sellers
                                    to maximize profit by making small alterations to the rate schedule based on local
                                    supply and demand
                                </li>
                            </ol>

                            <p>
                                This bifurcation allowed me to better tailor the user experience for each customer group.
                                Both small and large room sellers required the same capabilities in creating a rate
                                schedule, yet only the large room sellers required poweruser Rate Manager features commonly
                                used by Revenue Managers in large hotels and chains.
                            </p>

                            <h2>Rate Setup</h2>
                            <p>
                                The simplest possible rate strategy for a room seller is to have a single rate, for each
                                room type, for each night of the week. For a hotel with 5 room types, this results in 5
                                pieces of data the user is responsible for providing (see Figure 1 below). The most complex
                                possible rate strategy provided by our Rate Setup design allowed hoteliers to define an
                                occupancy-based price for adult and child, per room type, for each day of the week. For a
                                hotel with 5 room types, this can require the user to input potentially hundreds of prices
                                into the system to create a rate schedule.
                            </p>

                        </div>
                    </section>

                    <Showcase title="Rate Setup: Autopilot for Small Hoteliers">
                        <ShowcaseItem
                            label="Basic Complexity"
                            rootMedia="/images/hotels/rate-setup-basic.png"
                            figureNumber="1"
                        >
                            The simplest rate a room seller could configure was a static price per night, per room type,
                            every night of the week. Clicking the family button exposed additional options for
                            charging each additional child or adult.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Variable Day Pricing"
                            rootMedia="/images/hotels/rate-setup-vdp.png"
                            figureNumber="2"
                        >
                            A common simple pricing strategy used among our customers was Variable Day Pricing, in which a
                            room seller charges a rate based on the day of the week.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Occupancy Based Pricing"
                            rootMedia="/images/hotels/rate-setup-complex.png"
                            figureNumber="3"
                        >
                            The most complicated strategy used by our customers was Occupancy Based Pricing that differed
                            every day of the week. By toggling the "INCL" button, hoteliers retained complete control
                            over what rate was given to a customer given the number of room occupants and day of week.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Shipped"
                            rootMedia="/images/hotels/rate-setup-live.png"
                            figureNumber="4"
                        >
                            The live product showing a 2016 Spring Break promotional rate for a Maui hotel.
                        </ShowcaseItem>

                    </Showcase>


                    <section className="layout3col">
                        <div className="mainColumn">

                            <h2>Rate Planner</h2>
                            <p>
                                Once the customer had defined their rate schedule, Rate Planner allowed them to instantly
                                apply small changes across all of their rooms / rates in a matter of seconds. A common
                                scenario looked like this: a conference is coming to town next month. As a hotelier, I need
                                to increase the rates of all my rooms by 20% and enforce 2-night minimum bookings for the
                                weekend of the event. For Rate Planner to be successful, a user needed to be able to make
                                potentially hundreds of rate updates with two or three interface actions.
                            </p>

                        </div>
                    </section>

                    <Showcase title="Rate Planner: Course Corrections in Revenue Management Made Easy">
                        <ShowcaseItem
                            label="BAR"
                            rootMedia="/images/hotels/rp-bar.png"
                            figureNumber="1"
                        >
                            Rate Planner helped room sellers visualize their rate strategy using a familar calendar.
                            Here, the user is inspecting the price for each room type given a date.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Promotional Rate Availability"
                            rootMedia="/images/hotels/rp-promos.png"
                            figureNumber="2"
                        >
                            "What promotional rates am I selling next month?" Temporal questions like this are easily
                            answered by navigating to a weekeend. Conveniently hoteliers can flip a switch to
                            quickly course-correct their current rate management strategy.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="Promotional Rate"
                            rootMedia="/images/hotels/rp-promo-rate.png"
                            figureNumber="3"
                        >
                            Rate Planner allowed users the ability to drill down into individual room pricing and
                            availability, giving hoteliers a single screen they could use to manage rates.
                        </ShowcaseItem>
                        <ShowcaseItem
                            label="What should have been"
                            rootMedia="/images/hotels/rp-potential.png"
                            figureNumber="4"
                        >
                            After one year of working through the UX restraints of a 30 cell calendar, this is what
                            I thought Rate Planner should have looked like.
                        </ShowcaseItem>
                    </Showcase>


                    <section className="layout3col">
                        <div className="mainColumn">

                            <h2>Switching Sides: From Hotel Advocate to Unintended Adversary</h2>
                            <p>
                                By 2014, buuteeq was growing quickly. We’d raised a Series B venture round and were working
                                with some of the largest hotel chains in the US. Our small and large customers loved our
                                business because we gave them power to fight the commission enforced upon them from OTAs
                                like Booking and Expedia. These OTAs charged up to 30% commission for rooms sold on their
                                platforms and played dirty with tactics such as outspending hotels on their own branded
                                search engine marketing keywords. Ironically, Google was slowly removing OTAs from their own
                                game – by pushing OTA search results further down the page and driving users towards Google
                                services, sucking margin from hotels and OTAs alike. To make things even more complicated, a
                                new player named Airbnb was eating everyone’s lunch by allowing room seller to rent their
                                unit with a few clicks. This was the business environment when buuteeq was acquired by the
                                Priceline Group (Booking.com) in 2014.
                            </p>

                            <p>
                                During this period of the business, I was tasked with creating Booking’s first Property
                                Management System. I continued to work closely with my partner in crime, product manager,
                                and future co-founder, Amy Hutchins to define the product. The broader strategic goal of
                                this initiative was to effectively slow the advance of Airbnb who was only beginning to
                                threaten Booking.com’s long-tail customer revenue. This new product offering,
                                <ExtLink text="BookingSuite,"
                                         url="https://www.wsj.com/articles/booking-com-to-create-free-websites-for-small-hotels-1429704181"/>
                                would offer self-serve products to the longtail of small and independent room sellers.
                                BookingSuite would be built upon buuteeq’s vision of the hospitality cloud-based operating
                                system, but with backing of the world’s largest OTA. At the core of this OS model was the
                                Property Management System driven by the Rate Setup and Rate Planner products and
                                interfacing with customers via the website and booking engine products.
                            </p>

                            <p>
                                One of the most impactful changes for me after the acquisition was how my relationships with
                                customers changed. Now that we had been acquired by the world’s largest OTA, customers that
                                used to talk to me about their rate strategies now viewed me as the enemy. I found
                                ethnographic research was now incredibly difficult as each interviewee worried what they
                                might tell me could adversely impact their performance or ranking on Booking.com. I learned
                                that interview techniques that worked in the first several years of business no longer
                                elicited truthful responses. Fortunately, the acquisition by Booking granted us access to
                                massive amounts of data from hoteliers all over the world. I soon became spoiled with the
                                resources of one of the world’s largest travel companies.
                            </p>

                            <p>
                                In this phase of the business, my job shifted from experience designer and manager, towards
                                evangelist and diplomat from a newly acquired company in the Booking ecosystem.
                                Approximately once a month, I travelled throughout AMEA markets to meet with local Booking
                                offices to educate Booking employees about their newest product named BookingSuite. I loved
                                this period in my life, taking full advantage of the resources of a much larger company.
                            </p>

                            <p>
                                For instance, I could now perform user eye tracking studies in house at low cost, use
                                Booking’s incredible experimentation platform, and gain statistical significance orders of
                                magnitude faster than I ever had before.
                            </p>


                        </div>
                    </section>

                    <Showcase title="Booking Research">
                        <ShowcaseItem
                            label="Eye Tracking Study"
                            rootMedia="/images/hotels/eye-test.jpg"
                            figureNumber="1"
                        >
                            I didn't have budget for an eye tracking study at a startup. Getting better toys was
                            a huge perk in being a acquired.
                        </ShowcaseItem>

                        <ShowcaseItem
                            label="Heat Tracking"
                            rootMedia="/images/hotels/heat-tracking.jpg"
                            figureNumber="2"
                        >
                            Heatmap tests were extremely useful on our most highly trafficked customer sites. For these
                            premium customers, I had monthly calls with them analyzing the performance of their sites
                            after joining the platform.
                        </ShowcaseItem>

                    </Showcase>


                    <section className="layout3col">
                        <div className="mainColumn">

                            <p>
                                Though I enjoyed my time at Booking, I yearned to return to my roots in creating product at
                                a startup. It didn’t take long for me to begin talking with my future co-founders Brian Saab
                                and Amy Hutchins about what we might do next. What came next was one of the most fulfilling
                                periods of my career as we co-founded Unearth.
                            </p>

                            <h2>Next...</h2>
                            <p>
                                Continue the story of how Brian, Amy and I built <Link to="/construction">Unearth</Link>.
                            </p>
                        </div>
                    </section>
                </div>
        }
    ]
;

const getCase = (name) => {
    return cases.find(
        caseStudy => caseStudy.name === name
    );
}

export function CaseStudy(props) {
    const name = props.name;
    const content = getCase(name);

    return (
        <main>
            <header className="bgFill layout1col articleHeader"
                    style={{backgroundImage: `url(${content.headerImage})`}}>
                <div className="headerContent">
                    <div className="headline">
                        <div className="context">Challenge</div>
                        <h1>{content.headerTitle}</h1>
                    </div>
                </div>
            </header>
            <article className="articleBody">
                <section className="layout3col">
                    <div className="mainColumn">
                        <h2 className="summaryTitle">{content.summaryTitle}</h2>
                        <div className="summarySep">
                            <div className="label">Summary</div>
                            <div className="summaryRule"/>
                        </div>
                        <div className="summaryBody">
                            {content.summaryBody}
                        </div>
                    </div>
                    <ProjectMetadata meta={content.projectMetadata}/>
                </section>
                {content.contentBody}
            </article>
            <Lightbox triggerOpenClass="MediaItem" triggerCloseClass="lightbox-close"/>
        </main>
    )
}