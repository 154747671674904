import './App.css';
import './article.css';
import './home.css';
import {cases as caseStudies} from "./CaseStudy";
import {useNavigate, Outlet, NavLink, Link} from "react-router-dom";


function Card(props) {
    const url = props.href;
    let navigate = useNavigate();

    const handleClick = (event) => {
        event.preventDefault();
        navigate(url);
    }

    return (
        <div className="card" onClick={handleClick}>
            {props.children}
            <Link to={url}>{props.linkText}</Link>
        </div>
    )
}

function PersonalIntro() {
    return (
        <div className="intro">
            <div className="intro-graphic">
                <img src="/images/headshot.png" alt="Nate Miller"/>
            </div>
            <div className="intro-text">
                <em>Hi, I'm</em>
                <h1>nate miller</h1>
                <p>a technical Product Design leader skilled in crafting user experiences from <NavLink to="/about">zero to one</NavLink>.</p>
            </div>
        </div>
    )
}

function printCase(project) {
    return (
        <Card href={`/${project.name}`} linkText="Read Case Study">
            <div className="challenge"><Duration value={project.projectMetadata.duration} /></div>
            <h3>{project.headerTitle}</h3>
        </Card>
    )
}

function Duration(props) {
    return (
        <div className="duration">
            <span>{props.value}</span>
        </div>
    )
}

export function CaseStudies() {

    const sortedCases = () => {
        const sorted = caseStudies.sort((a, b) => (a.projectMetadata.duration > b.projectMetadata.duration) ? -1: 1);
        return sorted;
    }


    return (
        <main>
            <section className="layout3col">
                <div className="mainColumn">
                    <PersonalIntro/>

                    <section className="studies">
                        {sortedCases().map(printCase)}
                    </section>
                </div>
            </section>
        </main>
    );
}

export function About() {
    return (
        <main>
            <section className="layout3col">
                <div className="mainColumn">
                    <h1>About Nate</h1>
                    <p>
                        I started my first company in college only after failing to find a technology internship. It was
                        2001 and the dot com bubble burst had made my job prospects meager. The only person willing to
                        take a chance on me was me, so I decided to try and make my own job. I started my first agency
                        by cold emailing 400 small businesses offering a website for $300. I received 10 no thank you’s,
                        4 maybe’s, and 2 f—k you’s. My first customer came from that group of four – a beauty salon in
                        Seattle’s Crown Hill neighborhood. I still remember how much fun I had bringing her brand online
                        – animating the application of fingernail polish. I had no idea what I was doing, but I knew I
                        loved doing it. By the time I’d graduated college, I’d built a profitable business that
                        sustained a small team in an office in Pioneer Square.
                    </p>
                    <p>
                        During that period, I learned that passion could make difficult problems easier. Passion could
                        open doors that experience, socioeconomics, and privilege could not. Not to mention, when you
                        love what you do, it doesn’t feel like work. And if you continue enjoying it, and are willing to
                        confront your own biases, there’s a chance you’ll become good at it.
                    </p>
                    <p>
                        I've found that passion is a pre-requisite for strong product teams. Craftsmen and craftswomen
                        need to see the why behind their work. Strong product teams understand why their efforts
                        empower their customers, and how empathetic product design can positively influence the lives
                        of customers using our products.
                    </p>
                    <p>
                        Since college, I’ve continually followed passion in the work I choose – working with brands,
                        individuals, and venture capitalists to build a world I’d like to live in. I love
                        creating new ideas, products, and teams. You, too? Let’s talk.
                    </p>
                    <p><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/n8mlr">LinkedIn</a></p>
                    <p><a href="/nate-miller-resume.pdf">Download resume</a></p>
                </div>
            </section>
        </main>
    )
}

export function App() {
    return (
        <div className="App">

            <div className="siteMast">
                <div className="nav-mobile">
                    <div className="nav-block logo">
                        <a href="/"><img src="/images/nm-monogram.svg" alt="Nate Miller"/></a>
                    </div>
                    <div className="btnMenu">
                        <img src="/images/menu.svg" alt="Menu navigation"/>
                    </div>
                </div>

                <nav className="nav-block menu">
                    <ol>
                        <li className="menu-item">
                            <NavLink to="/">Case Studies</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/about">About</NavLink>
                        </li>
                        <li className="menu-item">
                            <a href="mailto:nate@natemiller.org">nate@natemiller.org</a>
                        </li>
                    </ol>
                </nav>
            </div>

            <Outlet/>
        </div>
    );
}

