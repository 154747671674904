import {useEffect} from "react";

/**
 * Renders a full screen lightbox
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function Lightbox(props) {
    const triggerOpenClass = props.triggerOpenClass || "lightbox-open";
    const triggerCloseClass = props.triggerCloseClass || "lightbox-close";


    useEffect(() => {
        const clearLightboxContent = () => {
            const mediaContainer = document.querySelector('#lightbox-media');
            while (mediaContainer.firstChild) {
                mediaContainer.removeChild(mediaContainer.firstChild);
            }
        }

        const showLightbox = (invoker) => {
            clearLightboxContent();
            const mediaTitle = invoker.getAttribute('alt');
            const caption = invoker.getAttribute('data-description');
            const media = invoker.cloneNode();
            media.classList.remove('MediaItem');
            media.style.cursor = 'auto';

            document.querySelector('body').classList.add('lightbox-visible');
            document.querySelector('#lightbox-title').textContent = mediaTitle;
            document.querySelector('#lightbox-media').appendChild(media);
            document.querySelector('#lightbox-caption').textContent = caption;
        }

        const closeLightbox = () => {
            document.querySelector('body').classList.remove('lightbox-visible');
            document.querySelector('#lightbox-title').textContent = '';
            document.querySelector('#lightbox-caption').textContent = '';
            clearLightboxContent();
        }

        const openers = document.querySelectorAll('.' + triggerOpenClass);
        openers.forEach((el) => {
            el.style.cursor = 'zoom-in';
        });

        const closers = document.querySelectorAll('.' + triggerCloseClass);
        closers.forEach((el) => {
            el.style.cursor = 'pointer';
        });

        document.addEventListener("keydown", (e) => {
            if (e.code === "Escape") {
                closeLightbox();
            }
        })

        document.addEventListener("click", (e) => {
            if (e.target.className.includes(triggerOpenClass)) {
                showLightbox(e.target);
            }

            if (e.target.className.includes(triggerCloseClass) ||
                ['lightbox', 'lightbox-context'].includes(e.target.className)) {
                closeLightbox();
            }
        });
    }, [triggerOpenClass, triggerCloseClass]);

    return (
        <div id="lightbox" className="lightbox">
            <div className="lightbox-buttons">
                <img src="/images/delete.svg" className="lightbox-button lightbox-close" alt="close button"/>
            </div>

            <div className="lightbox-inner">
                <div id="lightbox-media" className="media-wrap"/>
                <div className="lightbox-context">
                    <div id="lightbox-title"/>
                    <div id="lightbox-caption"/>
                </div>
            </div>
        </div>
    )
}

