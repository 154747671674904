import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {About, App, CaseStudies} from './App';
import {CaseStudy} from "./CaseStudy";
import { HashRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
        <Routes>
            <Route path="/" element={<App/>}>
                <Route index element={<CaseStudies />} />
                <Route path="/utilities" element={<CaseStudy name="utilities" />} />
                <Route path="/construction" element={<CaseStudy name="construction" />} />
                <Route path="/hotels" element={<CaseStudy name="hotels" />} />
                <Route path="/about" element={<About />} />
            </Route>
        </Routes>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
